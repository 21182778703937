<template>
  <article class="view-password-reset bg-wave-gradient">
    <div class="site-wrapper rythm-v-l flex-row flex-center-v flex-center-h">
      <div class="form-wrapper card shadow" v-if="!passwordResetSuccess && !passwordResetToken">
        <h1>Mot de passe oublié&nbsp;?</h1>
        <p>
          Entrez l'adresse e-mail associée à votre compte, vous recevrez les instructions pour changer de mot de passe.
        </p>

        <form @submit.prevent="requestPasswordReset">
          <div class="input-group">
            <label for="email">Votre adresse e-mail&nbsp;:</label>
            <input type="email" name="email" id="email" v-model="email" required />
          </div>

          <div class="input-group--controls">
            <button type="submit" class="button--primary">
              Réinitialiser le mot de passe
            </button>
          </div>
        </form>

        <pre v-if="debugMode && passwordResetDebug">{{ passwordResetDebug }}</pre>
      </div>

      <div class="form-wrapper card shadow" v-if="!passwordResetSuccess && passwordResetToken">
        <h1>Changement de mot de passe</h1>

        <form @submit.prevent="resetPassword">
          <div class="input-group">
            <label for="password">Mot de passe <span class="small">(12 caractères minimum)</span></label>
            <input type="password" name="password" id="password" v-model="resetPasswordData.password" :invalid="passwordIsInvalid" required />
          </div>

          <div class="input-group">
            <label for="password-check">Mot de passe <span class="small">(vérification)</span></label>
            <input type="password" name="password-check" id="password-check" v-model="resetPasswordData.passwordCheck" :invalid="passwordIsInvalid" required />
          </div>

          <ul class="form-validations">
            <li v-for="(validate, index) in validations" :key="index">
              <svg
                role="img"
                :class="validate.checked ? 'icon icon-checkmark checked' : 'icon icon-checkmark'"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="none"
                viewBox="0 0 22 22"
              >
                <svg:style>
                  .icon-checkmark .icon-checked {
                    opacity: 0;
                    fill: #3aaa35;
                  }
                  .icon-checkmark .icon-unchecked {
                    fill: #95c11f;
                    opacity: 1;
                  }

                  .icon-checkmark.checked .icon-checked {
                    opacity: 1;
                  }
                  .icon-checkmark.checked .icon-unchecked {
                    opacity: 0;
                  }
                </svg:style>
                <path
                  class="icon-unchecked"
                  fill="#95c11f"
                  fill-rule="evenodd"
                  d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                  clip-rule="evenodd"
                />
                <g class="icon-checked">
                  <path
                    fill="#3AAA35"
                    fill-rule="evenodd"
                    d="M14.663 2.77915C12.8902 1.98926 10.9096 1.79357 9.01657 2.22128C7.12351 2.649 5.41942 3.67719 4.15845 5.15252C2.89749 6.62785 2.14721 8.47126 2.01951 10.4078C1.89181 12.3444 2.39354 14.2704 3.44987 15.8985C4.50619 17.5266 6.06051 18.7697 7.88102 19.4423C9.70153 20.1149 11.6907 20.1809 13.5518 19.6307C15.413 19.0804 17.0464 17.9432 18.2084 16.3888C19.3705 14.8344 19.9989 12.9459 20 11.0052V10.0857C20 9.53344 20.4477 9.08572 21 9.08572C21.5523 9.08572 22 9.53344 22 10.0857V11.0057C21.9986 13.3778 21.2306 15.6864 19.8103 17.5863C18.39 19.4862 16.3936 20.876 14.1189 21.5486C11.8442 22.2211 9.41299 22.1404 7.18792 21.3183C4.96285 20.4963 3.06312 18.977 1.77206 16.9871C0.480993 14.9971 -0.13223 12.6432 0.0238445 10.2762C0.179919 7.90932 1.09693 5.65626 2.63811 3.85308C4.17929 2.0499 6.26206 0.793217 8.57581 0.270457C10.8896 -0.252303 13.3103 -0.0131332 15.477 0.952297C15.9815 1.17708 16.2082 1.76825 15.9834 2.27272C15.7587 2.7772 15.1675 3.00393 14.663 2.77915Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#3AAA35"
                    fill-rule="evenodd"
                    d="M21.7068 2.29816C22.0975 2.68848 22.0978 3.32165 21.7075 3.71237L11.7075 13.7224C11.52 13.9101 11.2656 14.0155 11.0002 14.0156C10.7349 14.0157 10.4805 13.9103 10.2929 13.7227L7.29289 10.7227C6.90237 10.3322 6.90237 9.69903 7.29289 9.30851C7.68342 8.91798 8.31658 8.91798 8.70711 9.30851L10.9996 11.601L20.2925 2.29886C20.6829 1.90814 21.316 1.90783 21.7068 2.29816Z"
                    clip-rule="evenodd"
                  />
                </g>
              </svg>

              <span class="caption">{{ validate.caption }}</span>
            </li>
          </ul>

          <div class="input-group--controls">
            <button type="submit" class="button--primary" :disabled="!formIsComplete">
              <svg role="img" class="icon icon-locker-closed" xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none" viewBox="0 0 20 22">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M3 11C2.44772 11 2 11.4477 2 12V19C2 19.5523 2.44772 20 3 20H17C17.5523 20 18 19.5523 18 19V12C18 11.4477 17.5523 11 17 11H3ZM0 12C0 10.3431 1.34315 9 3 9H17C18.6569 9 20 10.3431 20 12V19C20 20.6569 18.6569 22 17 22H3C1.34315 22 0 20.6569 0 19V12Z"
                  clip-rule="evenodd"
                />
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M10 2C8.93913 2 7.92172 2.42143 7.17157 3.17157C6.42143 3.92172 6 4.93913 6 6V10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10V6C4 4.4087 4.63214 2.88258 5.75736 1.75736C6.88258 0.632141 8.4087 0 10 0C11.5913 0 13.1174 0.632141 14.2426 1.75736C15.3679 2.88258 16 4.4087 16 6V10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10V6C14 4.93913 13.5786 3.92172 12.8284 3.17157C12.0783 2.42143 11.0609 2 10 2Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="caption">Modifier le mot de passe</span>
            </button>
          </div>
        </form>
      </div>

      <div class="card shadow" v-if="passwordResetSuccess">
        <h2>Votre mot de passe est modifié !</h2>
        <p>
          Vous pouvez vous connecter dès maintenant<br />
          avec votre nouveau mot de passe.
        </p>
        <p class="text-center">
          <router-link to="/login" class="button--cta">Connexion</router-link>
        </p>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "~@/scss/01-settings/settings.colors";
@import "~@/scss/01-settings/settings.typography";
@import "~@/scss/01-settings/settings.variables";

@import "~@/scss/06-components/components.form";
@import "~@/scss/07-themes/themes.infovac";

.bg-wave-gradient {
  background-size: 100% 16rem;
}

h1 {
  text-align: center;
}

.card {
  flex-basis: 32rem;
  text-align: center;
}

form {
  text-align: left;
  margin-top: 2rem;
}

.form-wrapper {
  background-color: $color-white;
}

.form-validations {
  margin-top: 1rem;
}

pre {
  white-space: pre-wrap;
  width: 100%;
  word-break: break-all;
  text-align: left;
  font-size: 0.875rem;
}
</style>

<script>
import { HTTP } from "../http-common";
import { emailIsValid } from "../libs/helpers";
import { EventBus } from "../event-bus.js";
import { log } from "logrocket";

export default {
  name: "PasswordReset",

  data: function() {
    return {
      passwordResetDebug: null, // admins only
      passwordResetToken: null,
      passwordResetPending: false,
      passwordResetSuccess: false,
      email: null,
      resetPasswordData: {
        password: "",
        passwordCheck: "",
      },
    };
  },

  methods: {
    requestPasswordReset: function(e) {
      this.$toasted.clear();
      let email = this.email;

      if (!this.validateEmail(email)) {
        return this.$toasted.global.appError({
          message: "L'adresse e-mail est invalide",
        });
      }

      this.$store
        .dispatch("REQUEST_PASSWORD_RESET", email)
        .then((response) => {
          this.passwordResetDebug = response;
          // EventBus.$emit(
          //   "trackEvent",
          //   "Réinitialisation mot de passe",
          //   "Demande initiale : envoyée"
          // );
          this.$toasted.global.appSuccess({
            message: "Consultez votre boîte de réception, un lien vous a été envoyé pour modifier votre mot de passe.",
          });
        })
        .catch((response) => {
          // Either the user and email doesn't match or the user is inactive or nonexistent
          if (response.status === 403) {
            // EventBus.$emit(
            //   "trackEvent",
            //   "Réinitialisation mot de passe",
            //   "Demande initiale : e-mail introuvable"
            // );
            this.$toasted.global.appError({
              message: "Cette adresse ne correspond pas à un utilisateur Infovac.",
            });
          }
          // Email is invalid
          else if (response.status === 422) {
            // EventBus.$emit(
            //   "trackEvent",
            //   "Réinitialisation mot de passe",
            //   "Demande initiale : e-mail invalide"
            // );
            this.$toasted.global.appError({
              message: "Une erreur est survenue, vérifiez votre adresse e-mail.",
            });
          }
          // Cannot send email, server error, …
          else {
            // EventBus.$emit(
            //   "trackEvent",
            //   "Réinitialisation mot de passe",
            //   "Demande initiale : erreur API"
            // );
            this.$toasted.global.appError({
              message: "Une erreur est survenue lors de l'envoi de l'email d'activation, veuillez nous contacter.",
            });
          }
        });
    },

    validateEmail: function(email) {
      return emailIsValid(email);
    },

    // Reset the password
    resetPassword: function() {
      let payload = {
        ...this.resetPasswordData,
        token: this.passwordResetToken,
      };

      this.$store
        .dispatch("RESET_PASSWORD", payload)
        .then(() => {
          // EventBus.$emit(
          //   "trackEvent",
          //   "Réinitialisation mot de passe",
          //   "Réinitalisation : succès"
          // );
          this.passwordResetSuccess = true;
        })
        .catch((error) => {
          // EventBus.$emit(
          //   "trackEvent",
          //   "Réinitialisation mot de passe",
          //   "Réinitalisation : erreur"
          // );

          this.passwordResetSuccess = false;
          this.passwordResetToken = null;
          this.$router.push("/password-reset");

          this.$toasted.global.appError({
            message: "Cette demande de réinitialisation a expirée, <br>demandez l'envoi d'un autre e-mail.",
          });
        });
    },
  },

  computed: {
    debugMode() {
      let debugOn = this.$route.query && this.$route.query.debug;
      return debugOn && this.user_is_admin;
    },

    // logged-in user (if any)
    user: function() {
      return this.$store.state.userData;
    },

    // Is the user an admin? (role 4)
    user_is_admin: function() {
      return this.user && this.user.roles.indexOf(4) !== -1;
    },

    formIsComplete: function() {
      let trues = 0;
      this.validations.forEach((el) => {
        if (el.checked) trues++;
      });
      return trues === this.validations.length;
    },

    validations: function() {
      return [
        {
          type: "passwordLength",
          caption: "La longueur du mot de passe est de 12 caractères minimum.",
          checked: this.resetPasswordData.password.length >= 12,
        },
        {
          type: "passwordMatch",
          caption: "Les mots de passe correspondent.",
          checked: this.resetPasswordData.password != "" && this.resetPasswordData.passwordCheck != "" && this.resetPasswordData.password === this.resetPasswordData.passwordCheck,
        },
      ];
    },

    passwordIsInvalid: function() {
      // No data
      if (this.resetPasswordData.password === "" && this.resetPasswordData.passwordCheck === "") return false;

      let passwordLength = this.validations.find((el) => el.type === "passwordLength");
      let passwordMatch = this.validations.find((el) => el.type === "passwordMatch");

      return !passwordLength.checked || !passwordMatch.checked;
    },
  },

  mounted() {
    this.passwordResetToken = this.$route.params.passwordResetToken;
  },
};
</script>
